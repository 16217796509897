import { ReactNode } from 'react';

import 'draft-js/dist/Draft.css';

import { useContentEditor } from './ContentEditor.hooks';
import { TContentEditorContextValues } from './ContentEditor.types';
import {
  ContentEditorControlsContainer,
  ContentEditorControlButtonContainer,
  ContentEditorHeaderContainer,
} from './ContentEditor.styles';
import {
  CONTENT_EDITOR_BLOCK_TYPES,
  CONTENT_EDITOR_INLINE_STYLES,
} from './ContentEditor.constants';

export function ContentEditorControls({ style, className }: WithStyle) {
  const { editorState, onToggleBlockType, onToggleInlineStyle } =
    useContentEditor();

  const selection = editorState.getSelection();

  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentInlineStyle = editorState.getCurrentInlineStyle();

  return (
    <ContentEditorControlsContainer className={className} style={style}>
      {CONTENT_EDITOR_INLINE_STYLES.map((type, index) => (
        <ContentEditorControlButton
          key={`content-editor-control-${index}`}
          active={currentInlineStyle.has(type.style)}
          label={type.label}
          onToggle={onToggleInlineStyle}
          style={type.style}
        />
      ))}

      {CONTENT_EDITOR_BLOCK_TYPES.map((type, index) => (
        <ContentEditorControlButton
          key={`content-editor-control-${index}`}
          active={type.style === currentBlockType}
          label={type.label}
          onToggle={onToggleBlockType}
          style={type.style}
        />
      ))}
    </ContentEditorControlsContainer>
  );
}

export function ContentEditorSubmit({
  children,
}: {
  children: (_: TContentEditorContextValues) => ReactNode;
}) {
  const contextProps = useContentEditor();

  return <>{children(contextProps)}</>;
}

export function Header({
  children,
  style,
  className,
}: WithStyle<{ children: ReactNode }>) {
  return (
    <ContentEditorHeaderContainer style={style} className={className}>
      {children}
    </ContentEditorHeaderContainer>
  );
}

export function ContentEditorHeader({
  children,
  style,
  className,
}: WithStyle<{ children: ReactNode }>) {
  return (
    <ContentEditorHeaderContainer style={style} className={className}>
      {children}
    </ContentEditorHeaderContainer>
  );
}

export function ContentEditorControlButton(props: {
  onToggle: (blockType: any) => void;
  active: boolean;
  label: string | ReactNode;
  style: string;
}) {
  return (
    <ContentEditorControlButtonContainer
      onMouseDown={(event) => {
        event.stopPropagation();
        event.preventDefault();

        props.onToggle(props.style);
      }}
      active={props.active}
    >
      {props.label}
    </ContentEditorControlButtonContainer>
  );
}
