import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';

import RestAPIClient from './RestAPIClient';
import {
  TRestAPIRequestError,
  TRestRequestOptions,
  TUseRestQueryHook,
  TUseRestLazyQueryHook,
} from './types.rest';

export function useRestQuery<TData = unknown, TVariables = unknown>(
  { url, baseURL }: { url: string; baseURL: string },
  options?: TRestRequestOptions<TData, TVariables>
): TUseRestQueryHook<TData, TVariables> {
  const [data, setData] = useState<TData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TRestAPIRequestError | undefined>(
    undefined
  );

  const query = useCallback(
    (_options?: TRestRequestOptions<TData, TVariables>) => {
      const { onCompleted, onError, mock, ...axiosOptions } =
        _options || options || {};

      const apiClient = RestAPIClient.getInstance({ mock, url });

      setLoading(true);
      setError(undefined);

      apiClient({
        baseURL,
        url,
        method: 'get',
        ...axiosOptions,
      })
        .then((response: AxiosResponse<TData>) => {
          setData(response.data);

          onCompleted && onCompleted(response.data);

          setLoading(false);
        })
        .catch((error: TRestAPIRequestError) => {
          setError(error);

          onError && onError(error);

          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  useEffect(() => {
    query();
  }, [query]);

  return { data, loading, error, refetch: query };
}

export function useRestLazyQuery<TData = unknown, TVariables = unknown>(
  { url, baseURL }: { url: string; baseURL: string },
  options?: TRestRequestOptions<TData, TVariables>
): TUseRestLazyQueryHook<TData, TVariables> {
  const [data, setData] = useState<TData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TRestAPIRequestError | undefined>(
    undefined
  );

  const query = useCallback(
    (_options?: TRestRequestOptions<TData, TVariables>) => {
      const { onCompleted, onError, mock, ...axiosOptions } = {
        ..._options,
        ...options,
      };

      const apiClient = RestAPIClient.getInstance({
        mock,
        url: _options?.url || url,
      });

      setLoading(true);
      setError(undefined);

      apiClient({
        baseURL,
        url,
        method: 'get',
        ...axiosOptions,
      })
        .then((response: AxiosResponse<TData>) => {
          setData(response.data);

          onCompleted && onCompleted(response.data);

          setLoading(false);
        })
        .catch((error: TRestAPIRequestError) => {
          setError(error);

          onError && onError(error);

          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  return [query, { data, loading, error }];
}
