import theme from '../theme/theme';

export default function BoldIcon({
  height = '24px',
  color = theme.colorsPalette.primary.lighter,
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      style={style}
      className={className}
      viewBox='0 0 10 13'
      fill='none'
      id='BoldIcon'
    >
      <path
        d='M7.83429 5.83C8.58 5.26429 9 4.45 9 3.46429C9 1.69 7.56 0.25 5.78571 0.25H0.642858C0.291429 0.25 0 0.541429 0 0.892858V11.6071C0 11.9586 0.291429 12.25 0.642858 12.25H6.42857C8.32286 12.25 9.85714 10.7157 9.85714 8.82143C9.85714 7.45 9.10286 6.38714 7.83429 5.83ZM4.5 1.53572C5.56286 1.53572 6.42857 2.40143 6.42857 3.46429C6.42857 4.52714 5.56286 5.39286 4.5 5.39286H2.56286V1.53572H4.5ZM2.57143 10.9643V6.67857H4.92857H5.14286C6.32571 6.67857 7.28571 7.63857 7.28571 8.82143C7.28571 10.0043 6.32571 10.9643 5.14286 10.9643H2.57143Z'
        fill={color}
      />
    </svg>
  );
}
