import Axios, { AxiosInstance } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

import { getAccessToken } from '../helpers.api';

import { TRestRequestMock } from './types.rest';

type TGetRestClientInstanceArgs<TData> = { mock?: TRestRequestMock<TData> };

export class RestAPIClient {
  public instance: AxiosInstance = Axios.create();
  public mockInstance: AxiosInstance = Axios.create();
  private mockAdapter: AxiosMockAdapter = new AxiosMockAdapter(
    this.mockInstance
  );

  public getInstance<TData>(
    args?: TGetRestClientInstanceArgs<TData> & { url?: string }
  ): AxiosInstance {
    if (args?.mock) {
      if (args.url) this.mockRequest({ ...args.mock, url: args.url });

      return this.mockInstance;
    }

    this.setHeaders();

    return this.instance;
  }

  private setHeaders() {
    this.instance.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers['Authorization'] = 'Bearer ' + getAccessToken();
          config.headers['Content-Type'] = 'application/json';
          config.headers['Access-Control-Allow-Origin'] = '*';
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  public mockRequest<TData>({
    mockAdapter = this.mockAdapter,
    url,
    data,
    statusCode = 200,
    timeout = 1000,
  }: TRestRequestMock<TData> & {
    url: string;
    mockAdapter?: AxiosMockAdapter;
  }) {
    return mockAdapter.onAny(url).reply(
      () =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve([statusCode, data]);
          }, timeout);
        })
    );
  }
}

const restAPIClient = new RestAPIClient();

export default restAPIClient;
