import { useState } from 'react';

import { useDeleteFilesMutation } from '../../../api/rest/resources/files.mutations';
import { useDownloadFilesMutation } from '../../../api/rest/resources/files.mutations';
import DownloadIcon from '../../icons/DownloadIcon';
import TrashIcon from '../../icons/TrashIcon';
import Button from '../Button/Button';
import Container from '../Container/Container';
import Dialog from '../Dialog/Dialog';
import Typography from '../Typography/Typography';

import { getFileNameStyle, getFileRootStyle } from './File.helpers';
import { TFileProps } from './File.types';

export default function File({
  id,
  name,
  withDownload,
  withDelete,
  onDownload,
  onDelete,
  highlight,
  style,
  className,
}: TFileProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [downloadFileMutation, { loading: loadingDownloadFileMutation }] =
    useDownloadFilesMutation({
      data: {
        file_ids: [id],
        filename: name,
      },
      onCompleted() {
        onDownload && onDownload();
      },
    });

  const [deleteFilesMutation, { loading: loadingDelete }] =
    useDeleteFilesMutation({
      onCompleted() {
        onDelete && onDelete();

        setShowDeleteDialog(false);
      },
    });

  const showActionButtons = !!withDownload || !!withDelete;

  return (
    <>
      <Container
        as='aside'
        row
        size='xsmall'
        bgColor='light'
        borderRadius='rounded'
        style={getFileRootStyle({ style, highlight })}
        className={className?.root}
      >
        <Typography
          as='p'
          variant='paragraph4'
          style={getFileNameStyle({ style, highlight })}
          className={className?.name}
        >
          {name}
        </Typography>

        {showActionButtons && (
          <Container row>
            {withDownload && (
              <Button
                variant='secondary'
                format='link'
                onClick={() => downloadFileMutation()}
              >
                {loadingDownloadFileMutation ? '...' : <DownloadIcon />}
              </Button>
            )}

            {withDelete && (
              <Button
                variant='secondary'
                format='link'
                onClick={() => setShowDeleteDialog(true)}
              >
                {loadingDelete ? (
                  '...'
                ) : (
                  <TrashIcon style={{ marginLeft: 8 }} />
                )}
              </Button>
            )}
          </Container>
        )}
      </Container>

      {showDeleteDialog && (
        <Dialog show={showDeleteDialog}>
          <Dialog.Header>
            <Dialog.Title>Remove file?</Dialog.Title>

            <Dialog.Close onClick={() => setShowDeleteDialog(false)} />
          </Dialog.Header>

          <Dialog.Body>
            <Typography as='p' variant='paragraph2'>
              Confirm you want to remove your files from the quote request. This
              action cannot be undone.
            </Typography>
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              variant='danger'
              onClick={() => deleteFilesMutation({ file_ids: [id] })}
              loading={loadingDelete}
            >
              Yes, remove
            </Button>
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  );
}
