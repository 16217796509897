import styled from 'styled-components';

export const BaseField = styled.input<{ error: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: calc(100% - 24px);
  padding: 12px;
  background-color: ${({ theme }) => theme.colorsPalette.black.lighter};
  border: 1px solid
    ${({ theme, error, required }) =>
      !!error && required
        ? theme.colorsPalette.error.default
        : theme.colorsPalette.gray.darker};
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;

export const BaseForm = styled.form`
  width: 100%;
`;

export const BaseFormLabel = styled.label<{
  error: string;
  required?: boolean;
}>`
  display: inline-block;
  font-family: 'Quicksand', sans-serif;
  color: ${({ required, error, theme }) =>
    required && error
      ? theme.colorsPalette.error.default
      : theme.colorsPalette.gray.light};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 8px;
`;

export const BaseFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const BaseFormFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
`;
