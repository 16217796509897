import { createContext, ReactNode, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Button from '../../components/Button/Button';
import Dialog from '../../components/Dialog/Dialog';
import theme from '../../theme/theme';
import SuccessIcon from '../../icons/SuccessIcon';
import ErrorIcon from '../../icons/ErrorIcon';

import {
  TShowAlertContextValues,
  TShowAlertProps,
} from './AlertsContext.types';
import {
  SnackbarMainContainer,
  SnackbarContentContainer,
} from './AlertsContext.styles';
import 'react-toastify/dist/ReactToastify.css';
import './AlertsContext.styles.css';
import Typography from '../../components/Typography/Typography';

export const AlertsContext = createContext({} as TShowAlertContextValues);

export function AlertsContextProvider({ children }: WithChildren) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<string | ReactNode>('');
  const [alertHeader, setAlertHeader] = useState<string | ReactNode>('');
  const [alertDeclineActionCallback, setAlertDeclineActionCallback] = useState<
    (() => void) | undefined
  >(undefined);

  function showAlert({
    content,
    header,
    declineActionCallback,
  }: TShowAlertProps) {
    setIsAlertOpen(true);
    setAlertContent(content);
    setAlertHeader(header);
    if (declineActionCallback)
      setAlertDeclineActionCallback(declineActionCallback);
  }

  function showSuccessSnackbar({
    title = 'Success',
    message,
  }: {
    title?: string;
    message: string;
  }) {
    toast(
      () => (
        <SnackbarMainContainer>
          <SuccessIcon
            style={{ marginRight: 8 }}
            color={theme.colorsPalette.green.default}
            height='24px'
          />

          <SnackbarContentContainer>
            <Typography as='h1' variant='title4' style={{ marginBottom: 4 }}>
              {title}
            </Typography>

            <Typography as='p' variant='paragraph3'>
              {message}
            </Typography>
          </SnackbarContentContainer>
        </SnackbarMainContainer>
      ),
      { className: 'success-snackbar' }
    );
  }

  function showErrorSnackbar({
    title = 'Error',
    message,
  }: {
    title?: string;
    message: string;
  }) {
    toast(
      () => (
        <SnackbarMainContainer>
          <ErrorIcon
            style={{ marginRight: 8 }}
            color={theme.colorsPalette.red.default}
            height='24px'
          />

          <SnackbarContentContainer>
            <Typography as='h1' variant='title4' style={{ marginBottom: 4 }}>
              {title}
            </Typography>

            <Typography as='p' variant='paragraph3'>
              {message}
            </Typography>
          </SnackbarContentContainer>
        </SnackbarMainContainer>
      ),
      { className: 'error-snackbar' }
    );
  }

  return (
    <AlertsContext.Provider
      value={{ showAlert, showSuccessSnackbar, showErrorSnackbar }}
    >
      {children}

      <ToastContainer
        autoClose={3000}
        position={toast.POSITION.BOTTOM_LEFT}
        hideProgressBar={true}
        theme='dark'
      />

      <Dialog show={isAlertOpen}>
        <Dialog.Header>
          <Dialog.Title>{alertHeader}</Dialog.Title>

          <Dialog.Close onClick={() => setIsAlertOpen(false)} />
        </Dialog.Header>

        <Dialog.Body>{alertContent}</Dialog.Body>

        <Dialog.Footer>
          <Button
            variant='primary'
            onClick={() => {
              setIsAlertOpen(false);

              if (alertDeclineActionCallback) alertDeclineActionCallback();
            }}
          >
            Ok
          </Button>
        </Dialog.Footer>
      </Dialog>
    </AlertsContext.Provider>
  );
}
