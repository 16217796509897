import { Route, Routes as BaseRoutes } from 'react-router-dom';

import Navigation from '../sections/Navigation/Navigation';
import QuoteRequestSection from '../sections/QuoteRequestSection/QuoteRequestSection';
import LocalizedFilesSection from '../sections/LocalizedFilesSection/LocalizedFilesSection';

import { ROUTES } from '../../constants/app.constants';

export default function Routes() {
  return (
    <>
      <Navigation />

      <BaseRoutes>
        <Route path={ROUTES.base} element={<QuoteRequestSection />} />

        <Route
          path={ROUTES.localizedFiles}
          element={<LocalizedFilesSection />}
        />
      </BaseRoutes>
    </>
  );
}
