import Checkbox from '../Checkbox/Checkbox';
import { TCheckboxProps } from '../Checkbox/Checkbox.types';
import ContentEditor from '../ContentEditor/ContentEditor';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import Select from '../Select/Select';
import { useForm } from './Form.hooks';
import {
  BaseFormFooter,
  BaseFormGroup,
  BaseFormLabel,
  BaseField,
} from './Form.styles';

import {
  TFormFieldProps,
  TFormFooterProps,
  TFormGroupProps,
  TFormLabelProps,
  TFormLanguageProps,
  TFormLanguageSelectProps,
} from './Form.types';

export function FormField<T = unknown>(props: TFormFieldProps<T>) {
  const form = useForm<T>();

  const error: string =
    (form.touched as any)[props.id] && (form.errors as any)[props.id];

  return (
    <BaseField
      {...props}
      id={props.id}
      name={props.id}
      type='text'
      onChange={form.handleChange}
      value={(form.values as any)[props.id]}
      error={error}
    />
  );
}

export function FormLabel<T = unknown>(props: TFormLabelProps) {
  const form = useForm<T>();

  const error: string =
    props.id &&
    (form.touched as any)[props.id] &&
    (form.errors as any)[props.id];

  return <BaseFormLabel htmlFor={props.id} error={error} {...props} />;
}

export function FormSelect<T = unknown, IsMulti extends boolean = false>({
  id,
  ...props
}: TFormLanguageProps<T, IsMulti>) {
  const form = useForm<T>();

  const error = !!(id && (form.touched as any)[id] && (form.errors as any)[id]);

  return (
    <>
      <Select<T, IsMulti>
        {...props}
        value={(form.values as any)[id]}
        onChange={(content) => form.setFieldValue(id, content)}
        error={error}
        inputId={id}
        name={id}
        onBlur={form.handleBlur}
      />
    </>
  );
}

export function FormLanguageSelect<
  T = unknown,
  IsMulti extends boolean = false
>({ id, ...props }: TFormLanguageSelectProps<IsMulti>) {
  const form = useForm<T>();

  const error = !!(id && (form.touched as any)[id] && (form.errors as any)[id]);

  return (
    <LanguageSelect<IsMulti>
      {...props}
      value={(form.values as any)[id]}
      onChange={(content) => form.setFieldValue(id, content)}
      error={error}
      inputId={id}
      name={id}
      onBlur={form.handleBlur}
    />
  );
}

export function FormCheckbox<T = unknown>(props: TCheckboxProps & WithId) {
  const form = useForm<T>();

  return (
    <Checkbox
      onChange={(event) => form.setFieldValue(props.id, event.target.checked)}
      checked={(form.values as any)[props.id]}
      {...props}
    />
  );
}

export function FormContentEditor<T = unknown>(props: WithId) {
  const form = useForm<T>();

  return (
    <ContentEditor
      onChange={(content) => form.setFieldValue(props.id, content)}
      value={(form.values as any)[props.id]}
    >
      <ContentEditor.Header>
        <ContentEditor.Controls />
      </ContentEditor.Header>
    </ContentEditor>
  );
}

export function FormGroup(props: TFormGroupProps) {
  return <BaseFormGroup {...props} />;
}

export function FormFooter(props: TFormFooterProps) {
  return <BaseFormFooter {...props} />;
}
