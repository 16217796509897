import theme from '../theme/theme';

export default function OListIcon({
  height = '24px',
  color = theme.colorsPalette.primary.lighter,
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      style={style}
      className={className}
      viewBox='0 0 17 13'
      fill='none'
      id='OListIcon'
    >
      <path d='M7.39062 2.13635H15.8452' stroke={color} />
      <path
        d='M4.17429 4.77362V5.46821H1.38908V4.77362H2.37451V2.05684C2.37451 1.99754 2.37565 1.93709 2.37793 1.8755C2.38021 1.81391 2.38363 1.75118 2.38819 1.68731L1.73808 2.22793C1.69246 2.26214 1.64798 2.28381 1.60464 2.29294C1.5613 2.30206 1.52024 2.3032 1.48146 2.29636C1.44268 2.28723 1.40846 2.27355 1.37881 2.2553C1.34916 2.23477 1.32635 2.21424 1.31038 2.19371L1.0127 1.79338L2.53874 0.5H3.31546V4.77362H4.17429Z'
        fill={color}
      />
      <path
        d='M3.9589 11.6343C4.06155 11.6343 4.14139 11.6628 4.19842 11.7199C4.25773 11.7769 4.28738 11.8522 4.28738 11.9457V12.5H0.845215V12.1921C0.845215 12.1328 0.857761 12.0689 0.882853 12.0004C0.907945 11.9297 0.950145 11.8659 1.00945 11.8088L2.48076 10.3341C2.60622 10.2087 2.71685 10.0889 2.81265 9.97484C2.90846 9.85851 2.9883 9.74559 3.05217 9.6361C3.11604 9.52433 3.16394 9.41141 3.19588 9.29736C3.22781 9.1833 3.24378 9.06355 3.24378 8.93809C3.24378 8.71682 3.18675 8.54916 3.0727 8.4351C2.95864 8.31877 2.79783 8.2606 2.59025 8.2606C2.499 8.2606 2.4146 8.27429 2.33705 8.30166C2.26177 8.32675 2.19334 8.36211 2.13175 8.40773C2.07244 8.45335 2.02112 8.50696 1.97778 8.56855C1.93444 8.63014 1.9025 8.69743 1.88197 8.77043C1.84091 8.88448 1.78502 8.9609 1.71431 8.99968C1.64588 9.03617 1.54779 9.04416 1.42005 9.02363L0.920491 8.93466C0.956988 8.69287 1.02542 8.48073 1.12579 8.29824C1.22616 8.11575 1.35162 7.96406 1.50217 7.84316C1.65272 7.72226 1.82494 7.63216 2.01884 7.57285C2.21273 7.51126 2.42145 7.48047 2.64499 7.48047C2.87995 7.48047 3.09323 7.51583 3.28484 7.58654C3.47873 7.65497 3.64411 7.75192 3.78098 7.87738C3.91784 8.00056 4.02391 8.14997 4.09919 8.32561C4.17447 8.50126 4.2121 8.69515 4.2121 8.90729C4.2121 9.08978 4.18587 9.25858 4.13341 9.41369C4.08094 9.56881 4.00909 9.71708 3.91784 9.85851C3.82888 9.99765 3.72509 10.1334 3.60647 10.2657C3.48786 10.398 3.3624 10.5314 3.23009 10.666L2.17623 11.7438C2.29257 11.7096 2.40776 11.6834 2.52182 11.6651C2.63587 11.6446 2.74308 11.6343 2.84345 11.6343H3.9589Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.61792 2.13635C6.61792 1.86021 6.84178 1.63635 7.11792 1.63635H16.1179C16.3941 1.63635 16.6179 1.86021 16.6179 2.13635C16.6179 2.41249 16.3941 2.63635 16.1179 2.63635H7.11792C6.84178 2.63635 6.61792 2.41249 6.61792 2.13635Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.61792 6.5C6.61792 6.22386 6.84178 6 7.11792 6H16.1179C16.3941 6 16.6179 6.22386 16.6179 6.5C16.6179 6.77614 16.3941 7 16.1179 7H7.11792C6.84178 7 6.61792 6.77614 6.61792 6.5Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.61792 10.8636C6.61792 10.5875 6.84178 10.3636 7.11792 10.3636H16.1179C16.3941 10.3636 16.6179 10.5875 16.6179 10.8636C16.6179 11.1398 16.3941 11.3636 16.1179 11.3636H7.11792C6.84178 11.3636 6.61792 11.1398 6.61792 10.8636Z'
        fill={color}
      />
    </svg>
  );
}
