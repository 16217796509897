import { QUOTE_REQUESTER_API_V1_BASE_URL } from '../../../constants/api.constants';

import { useQuoteFiles } from '../../../app/context/QuoteFilesContext/QuoteFilesContext.hooks';
import { useRestMutation } from '../mutations.rest';
import { useAlerts } from '../../../app/context/AlertsContext/AlertsContext.hooks';
import { TRestRequestOptions } from '../types.rest';
import { handleMediaDownload } from '../../../app/helpers/api.helpers';

export type TDeleteQuoteFilesMutationData = unknown;

export type TDeleteQuoteFilesMutationVariables = {
  file_ids: string[];
};

export function useDeleteFilesMutation(
  options?: TRestRequestOptions<
    TDeleteQuoteFilesMutationData,
    TDeleteQuoteFilesMutationVariables
  >
) {
  const { handleDelete } = useQuoteFiles();

  const { showSuccessSnackbar, showErrorSnackbar } = useAlerts();

  const mutation = useRestMutation<
    TDeleteQuoteFilesMutationData,
    TDeleteQuoteFilesMutationVariables
  >({ baseURL: QUOTE_REQUESTER_API_V1_BASE_URL, url: '/files' }, 'delete', {
    onCompleted(response, variables) {
      showSuccessSnackbar({ message: 'File successfully deleted.' });

      handleDelete(variables?.file_ids || []);

      options?.onCompleted && options.onCompleted(response);
    },
    onError(error) {
      showErrorSnackbar({ message: 'Error deleting file. Please try again.' });

      options?.onError && options.onError(error);
    },
  });

  return mutation;
}

export type TDownloadFilesMutationData = unknown;

export type TDownloadFilesMutationVariables = {
  file_ids: string[];
  filename: string;
};

export function useDownloadFilesMutation(
  options?: TRestRequestOptions<
    TDownloadFilesMutationData,
    TDownloadFilesMutationVariables
  >
) {
  const { showErrorSnackbar } = useAlerts();
  const fileId = options?.data?.file_ids[0]

  const mutation = useRestMutation<
    TDownloadFilesMutationData,
    TDownloadFilesMutationVariables
  >(
    { baseURL: QUOTE_REQUESTER_API_V1_BASE_URL, url: `/files/${fileId}/download` },
    'get',
    {
      responseType: 'blob',
      ...options,
      onCompleted(data) {
        handleMediaDownload(
          data,
          options?.data?.filename || 'Quote request files'
        ).then(() => options?.onCompleted && options.onCompleted(data));
      },
      onError() {
        showErrorSnackbar({
          message: 'Error downloading your files. Please try again later.',
        });
      },
    }
  );

  return mutation;
}
