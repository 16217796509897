import styled from 'styled-components';

import theme from '../../theme/theme';

const publicDraftEditorPlaceholderRoot = 'public-DraftEditorPlaceholder-root';
const publicDraftEditorContent = 'public-DraftEditor-content';
const richEditorHidePlaceholder = 'RichEditor-hidePlaceholder';
const richEditorBlockquote = 'RichEditor-blockquote';
const publicDraftStyleDefaultPre = 'public-DraftStyleDefault-pre';

export const ContentEditorContainer = styled.aside`
  background: ${theme.colorsPalette.black.lighter};
  border: 1px solid ${theme.colorsPalette.secondary.darker};
  border-radius: 6px;
  font-size: ${theme.fontSizes.regular};
  padding: 12px;
  z-index: 0;
  color: ${theme.colorsPalette.white.dark};
  font-family: 'Quicksand', sans-serif;
  line-height: 16px;
  width: calc(100% - 24px);
`;

export const ContentEditorInnerContainer = styled.div.attrs({
  publicDraftEditorPlaceholderRoot,
  publicDraftEditorContent,
  richEditorHidePlaceholder,
  richEditorBlockquote,
  publicDraftStyleDefaultPre,
})`
  cursor: text;
  margin-bottom: 24px;

  .${publicDraftEditorPlaceholderRoot}, .${publicDraftEditorContent} {
    margin: 0 -16px -16px;
    padding: 16px;
  }
  .${publicDraftEditorContent} {
    min-height: 100px;
  }
  .${richEditorBlockquote} {
    border-left: 5px solid #eee;
    color: #666;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  .${publicDraftStyleDefaultPre} {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    padding: 20px;
  }
  /* TODO: This styles are not being triggered. Solve this issue to enable 
  placeholdere prop */
  /* .${richEditorHidePlaceholder}, .${publicDraftEditorPlaceholderRoot} {
    display: none;
  } */
`;

export const ContentEditorControlsContainer = styled.section`
  user-select: none;
`;

export const ContentEditorControlButtonContainer = styled.span<{
  active: boolean;
}>`
  color: ${({ active }) =>
    active ? theme.colorsPalette.blue.default : theme.colorsPalette.gray.dark};
  cursor: pointer;
  margin-right: 8px;
  padding: 4px 0;
  display: inline-block;
  min-width: 24px;
  min-height: 16px;
`;

export const ContentEditorHeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
