import BoldIcon from '../../icons/BoldIcon';
import ItalicIcon from '../../icons/ItalicIcon';
import StrikethroughIcon from '../../icons/StrikethroughIcon';
import UListIcon from '../../icons/UListIcon';
import OListIcon from '../../icons/OListIcon';

export const CONTENT_EDITOR_BLOCK_TYPES = [
  { label: <UListIcon height='12px' />, style: 'unordered-list-item' },
  { label: <OListIcon height='12px' />, style: 'ordered-list-item' },
];

export const CONTENT_EDITOR_INLINE_STYLES = [
  { label: <BoldIcon height='12px' />, style: 'BOLD' },
  { label: <ItalicIcon height='12px' />, style: 'ITALIC' },
  { label: <StrikethroughIcon height='12px' />, style: 'STRIKETHROUGH' },
];

export const CONTENT_EDITOR_CUSTOM_CONFIGS = {
  draftJsToMd: {
    STRIKETHROUGH: '~~',
  },
  mdToDraftJs: {
    inlineStyles: {
      Delete: {
        type: 'STRIKETHROUGH',
        symbol: '~~',
      },
    },
  },
  styleMap: {
    STRIKETHROUGH: {
      textDecoration: 'line-through',
    },
  },
};
