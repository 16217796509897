import { createContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  TGetQuoteFilesQueryData,
  useGetQuoteFilesLazyQuery,
} from '../../../api/rest/resources/files.queries';
import { TUseRestQueryHook } from '../../../api/rest/types.rest';
import { ROUTES } from '../../../constants/app.constants';

import {
  TQuoteFilesContextValues,
  TTQuoteFilesHandleDeleteArgs,
} from './QuoteFilesContext.types';

export const QuoteFilesContext = createContext({} as TQuoteFilesContextValues);

export function QuoteFilesContextProvider(props: WithChildren) {
  const [data, setData] =
    useState<
      ValueOf<Pick<TUseRestQueryHook<TGetQuoteFilesQueryData>, 'data'>>
    >();

  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();

  const [lazyQuery, { data: baseData, loading, error }] =
    useGetQuoteFilesLazyQuery();

  const quoteRequestId = searchParams.get('quote_request_id');

  function handleDelete(file_ids: TTQuoteFilesHandleDeleteArgs) {
    const updatedData =
      data?.files.filter((file) => !file_ids.includes(file.id)) || [];

    setData({ files: updatedData });
  }

  useEffect(() => {
    setData(baseData);
  }, [baseData]);

  useEffect(() => {
    if (pathname === ROUTES.base && quoteRequestId)
      lazyQuery({ params: { quote_request_id: quoteRequestId } });
  }, [pathname, quoteRequestId, lazyQuery]);

  return (
    <QuoteFilesContext.Provider
      value={{ data, loading, error, refetch: lazyQuery, handleDelete }}
    >
      {props.children}
    </QuoteFilesContext.Provider>
  );
}
