import styled from 'styled-components';

export const SnackbarMainContainer = styled.aside`
  display: flex;
  align-items: center;
`;

export const SnackbarContentContainer = styled.aside`
  display: flex;
  flex-direction: column;
`;
