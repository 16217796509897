import { Children } from 'react';
import styled from 'styled-components';

import CloseIcon from '../../icons/CloseIcon';
import theme from '../../theme/theme';

import { TDialogCloseProps, TDialogHeaderProps } from './Dialog.types';
import { DialogBaseHeader, DialogCloseButton } from './Dialog.styles';
import Typography from '../Typography/Typography';

export function DialogHeader({
  children,
  style,
  className,
}: TDialogHeaderProps) {
  const childrenCount = Children.count(children);

  return (
    <DialogBaseHeader
      childrenCount={childrenCount}
      className={className}
      style={style}
    >
      {children}
    </DialogBaseHeader>
  );
}

export function DialogClose({ className, style, ...props }: TDialogCloseProps) {
  return (
    <DialogCloseButton {...props} className={className} style={style}>
      <CloseIcon color={theme.colorsPalette.gray.light} height='16px' />
    </DialogCloseButton>
  );
}

export function DialogTitle(props: WithChildren & WithStyle) {
  return (
    <Typography
      as='h1'
      variant='title3'
      style={{ ...props.style, marginBottom: 0 }}
      className={props.className}
    >
      {props.children}
    </Typography>
  );
}

export const DialogBody = styled.div`
  width: 100%;
  overflow-y: scroll;

  p {
    color: ${({ theme }) => theme.colorsPalette.gray.default};
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  width: 100%;
`;
