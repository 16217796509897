import { QUOTE_REQUESTER_API_V1_BASE_URL } from '../../../constants/api.constants';

import { useRestLazyQuery, useRestQuery } from '../queries.rest';
import { TRestRequestOptions } from '../types.rest';

export type TGetQuoteFilesQueryData = {
  files: WithId<TLocalizationFile>[];
};

export type TGetQuoteFilesQueryVariables = {
  quote_request_id: string;
};

export function useGetQuoteFilesLazyQuery(
  options?: TRestRequestOptions<
    TGetQuoteFilesQueryData,
    TGetQuoteFilesQueryVariables
  >
) {
  const query = useRestLazyQuery<
    TGetQuoteFilesQueryData,
    TGetQuoteFilesQueryVariables
  >(
    { baseURL: QUOTE_REQUESTER_API_V1_BASE_URL, url: '/files' },
    {
      onError(error) {
        options?.onError && options.onError(error);
      },
      ...options,
    }
  );

  return query;
}

export type TGetLocalizedFilesQueryData = WithId<TLocalizationFile>[];

export type TGetLocalizedFilesQueryVariables = {
  quote_request_id: string;
};

export function useGetLocalizedFilesQuery(
  options?: TRestRequestOptions<
    TGetLocalizedFilesQueryData,
    TGetLocalizedFilesQueryVariables
  >
) {
  const query = useRestQuery<
    TGetLocalizedFilesQueryData,
    TGetLocalizedFilesQueryVariables
  >(
    { url: '/files/localized', baseURL: QUOTE_REQUESTER_API_V1_BASE_URL },
    {
      onError(error) {
        options?.onError && options.onError(error);
      },
      ...options,
    }
  );

  return query;
}
