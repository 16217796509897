import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useGetLocalizedFilesQuery } from '../../../api/rest/resources/files.queries';
import Container from '../../components/Container/Container';
import Typography from '../../components/Typography/Typography';
import File from '../../components/File/File';

import {
  getFilesExpTimeText,
  getFilesMaxExpDate,
} from './LocalizedFilesSection.helpers';
import Button from '../../components/Button/Button';
import { useDownloadFilesMutation } from '../../../api/rest/resources/files.mutations';

export default function LocalizedFilesSection() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const {
    data: localizationFilesData,
    loading: loadingLocalizedFilesData,
    error: errorOnLocalizedFilesData,
  } = useGetLocalizedFilesQuery({
    params: { token },
  });

  const [downloadFilesMutation, { loading: loadingDownloadFilesMutation }] =
    useDownloadFilesMutation();

  const filesMaxExpDate = getFilesMaxExpDate(
    localizationFilesData || []
  );

  const filesExpTimeText = getFilesExpTimeText(filesMaxExpDate);

  if (loadingLocalizedFilesData)
    return (
      <Container as='section' fluid size='regular'>
        <Typography as='p' variant='paragraph3'>
          Loading localization files...
        </Typography>
      </Container>
    );

  if (errorOnLocalizedFilesData)
    return (
      <Container as='section' fluid size='regular'>
        <Typography as='p' variant='paragraph3'>
          Error loading localization files.
        </Typography>
      </Container>
    );

  if (localizationFilesData?.length === 0)
    return (
      <Container as='section' fluid size='regular'>
        <Typography as='p' variant='paragraph3'>
          There are no files to download. Contact Tech Support to recover the
          files.
        </Typography>
      </Container>
    );

  return (
    <Container as='section' fluid size='regular'>
      <Typography
        as='h1'
        variant='paragraph1'
        style={{ width: '100%', textAlign: 'center', marginBottom: 24 }}
      >
        The expiration time for downloading the files is {filesExpTimeText}.
      </Typography>

      {localizationFilesData?.map((localizedFile) => (
        <File
          key={localizedFile.id}
          id={localizedFile.id}
          name={localizedFile.name}
          withDownload
          style={{ root: { width: 'calc(100% - 24px)', marginBottom: 8 } }}
          highlight
        />
      ))}

      <Container
        as='footer'
        fluid
        style={{ width: '100%', alignItems: 'flex-end', marginTop: 16 }}
      >
        <Button
          variant='primary'
          onClick={() =>
            downloadFilesMutation({
              file_ids: localizationFilesData?.map((file) => file.id) || [],
              filename: `${dayjs().format('DD/MM/YY')} Alocai Quote files`,
            })
          }
          loading={loadingDownloadFilesMutation}
        >
          Download all
        </Button>
      </Container>
    </Container>
  );
}
