import { getButtonVariantStyles } from './Button.helpers';
import { ButtonContainer } from './Button.styles';
import { TButtonProps } from './Button.types';

export default function Button({
  as: Component = 'button',
  variant,
  format,
  size,
  disabled,
  loading,
  style,
  children,
  className,
  ...props
}: TButtonProps) {
  const variantStyle = getButtonVariantStyles({
    variant,
    format,
    size,
  });

  return (
    <ButtonContainer
      as={Component}
      disabled={disabled}
      style={style}
      className={className}
      variantStyle={variantStyle}
      {...props}
    >
      {loading ? '...' : children}
    </ButtonContainer>
  );
}
