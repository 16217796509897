import theme from '../theme/theme';

export default function TrashIcon({
  height = '24px',
  color = theme.colorsPalette.primary.lighter,
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      className={className}
      style={style}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M5.82353 4.5H18.1765C18.8824 4.5 19.5 5.2 19.5 6V7.5H4.5V6C4.5 5.2 5.11765 4.5 5.82353 4.5Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 7.5V21.1765C18.5 21.8824 17.8933 22.5 17.2 22.5H6.8C6.10667 22.5 5.5 21.8824 5.5 21.1765V7.5'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 10V19M9.5 19V10'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 4.5V3C9.5 2.2 10 1.5 10.5714 1.5H13.4286C14 1.5 14.5 2.2 14.5 3V4.5'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
