import { FormikProps } from 'formik';

import theme from '../../theme/theme';
import { SERVICES } from '../../../constants/translation.constants';
import { TERMS_URL, PRIVACY_URL } from '../../../constants/app.constants';
import { useRUM } from '../../context/RUMContext/RUMContext.hooks';
import { TQuoteFilesContextValues } from '../../context/QuoteFilesContext/QuoteFilesContext.types';
import Button from '../../components/Button/Button';
import File from '../../components/File/File';
import Form from '../../components/Form/Form';
import Typography from '../../components/Typography/Typography';

import { TQuoteRequestFormValues } from './QuoteRequestSection.types';

export function QuoteRequestHeader() {
  const { ga } = useRUM();

  return (
    <>
      <Typography as='h1' variant='title3' style={{ marginBottom: 8 }}>
        Request a quote with Alocai
      </Typography>

      <Typography
        variant='link'
        as='a'
        href='https://www.alocai.com/services/professional-translation'
        target='_blank'
        style={{ marginBottom: 16 }}
        onClick={() =>
          ga.handleTrackEvent({
            category: 'About',
            action: 'About Alocai link click',
          })
        }
      >
        What is Alocai?
      </Typography>
    </>
  );
}

export function QuoteRequestFilesList({
  data,
  loading,
  error,
}: Pick<TQuoteFilesContextValues, 'data' | 'loading' | 'error'>) {
  if (loading)
    return (
      <Typography as='p' variant='paragraph3'>
        Loading localization files...
      </Typography>
    );

  if (error)
    return (
      <Typography as='p' variant='paragraph3'>
        Error loading localization files.
      </Typography>
    );

  if (data?.files.length === 0)
    return (
      <Typography as='p' variant='paragraph3'>
        We will require your files after receiving this quote request. If you
        want to attach back the files, please go back to the Dialog Editor and
        generate a new quote.
      </Typography>
    );

  return (
    <>
      <Typography as='h2' variant='title4'>
        These are your localization files exported from Pixel Crushers:
      </Typography>

      {data?.files.map((quoteFile) => (
        <File
          key={quoteFile.id}
          id={quoteFile.id}
          name={quoteFile.name}
          withDownload
          withDelete
          style={{ root: { width: 'calc(100% - 24px)', marginBottom: 8 } }}
        />
      ))}
    </>
  );
}

export function QuoteRequestForm({
  form,
  loadingRequestQuoteMutation,
}: {
  form: FormikProps<TQuoteRequestFormValues>;
  loadingRequestQuoteMutation: boolean;
}) {
  const submitButtonDisabled =
    !form.values.tcAgreed || Object.keys(form.errors).length > 0;

  const termsContainer = {
    display: 'flex',
    alignItems: 'center'
  }
  const termsLinkContainer = {
    color: '#fff',
    fontSize: '14px'
  }
  const termsLink = {
    textDecoration: 'none',
    color: theme.colorsPalette.cyan.light,
    marginLeft: '4px',
  }

  return (
    <Form<TQuoteRequestFormValues> form={form} style={{ marginTop: 16 }}>
      <Form.Group>
        <Form.Label id='source_language' required>
          Source language*
        </Form.Label>

        <Form.LanguageSelect id='source_language' languageVariant='source' />
      </Form.Group>

      <Form.Group>
        <Form.Label id='target_languages' required>
          Target language(s)*
        </Form.Label>

        <Form.LanguageSelect
          id='target_languages'
          languageVariant='target'
          isMulti
        />
      </Form.Group>

      <Form.Group>
        <Form.Label id='services' required>
          Services*
        </Form.Label>

        <Form.Select
          id='services'
          isMulti
          options={SERVICES}
          getOptionValue={(service) => service.code}
          getOptionLabel={(service) => service.name}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label id='userName' required>
          Full name*
        </Form.Label>

        <Form.Field
          id='userName'
          name='userName'
          placeholder='Your fullname'
          onBlur={form.handleBlur}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label id='merchantName' required>
          Company name*
        </Form.Label>

        <Form.Field
          id='merchantName'
          name='merchantName'
          placeholder='Your company name'
          onBlur={form.handleBlur}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label id='userEmail' required>
          Email address*
        </Form.Label>

        <Form.Field
          id='userEmail'
          name='userEmail'
          placeholder='Your email address'
          onBlur={form.handleBlur}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Add your instructions or a message</Form.Label>

        <Form.ContentEditor id='instructions' />
      </Form.Group>

      <div style={termsContainer}>
        <Form.Checkbox
          id='tcAgreed'
          label='I have read and agreed to the'
        />
        <div style={termsLinkContainer}>
          <span><a style={termsLink} href={TERMS_URL} target="_blank" rel="noopener noreferrer">Privacy Policy</a></span> and
          <span><a style={termsLink} href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span></div>
      </div>

      <Form.Footer>
        <Button
          variant='primary'
          type='submit'
          disabled={submitButtonDisabled}
          loading={loadingRequestQuoteMutation}
        >
          Send request
        </Button>
      </Form.Footer>
    </Form>
  );
}

export function QuoteRequestOnCompletedMessage() {
  return (
    <>
      <Typography
        as='h1'
        variant='title2'
        style={{ marginBottom: 24, width: '100%', textAlign: 'center' }}
      >
        First Mission Complete!
      </Typography>

      <Typography
        as='p'
        variant='paragraph1'
        style={{ width: '100%', textAlign: 'center' }}
      >
        We are pleased that you are interested in working with us. <br /> A
        project manager will work through the details and contact you within 24
        hours with a quote.
      </Typography>
    </>
  );
}
