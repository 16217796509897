import Container from '../../components/Container/Container';
import AlocaiLogoIcon from '../../icons/AlocaiLogoIcon';

export default function Navigation() {
  return (
    <Container as='nav' fluid row size='regular'>
      <AlocaiLogoIcon />
    </Container>
  );
}
