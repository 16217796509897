import theme from '../theme/theme';

export default function DownloadIcon({
  height = '24px',
  color = theme.colorsPalette.primary.lighter,
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      className={className}
      style={style}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 14L12 19L7 14'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 19V5'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
