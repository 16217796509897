import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import {
  TRUMContextValues,
  THandleGATrackEventOptions,
} from './RUMContext.types';

export const RUMContext = createContext({} as TRUMContextValues);

export function RUMContextProvider(props: WithChildren) {
  const [gaInit, setGaInit] = useState(false);

  const location = useLocation();

  function handleTrackGAEvent(options: THandleGATrackEventOptions) {
    ReactGA.event(options);
  }

  useEffect(() => {
    if (process.env.REACT_APP_GA4_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID);
      ReactGA.send({ hitType: 'pageview', page: '/' });

      setGaInit(true);
    }
  }, []);

  useEffect(() => {
    if (gaInit) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [gaInit, location]);

  return (
    <RUMContext.Provider
      value={{
        ga: {
          init: gaInit,
          setInit: setGaInit,
          handleTrackEvent: handleTrackGAEvent,
        },
      }}
    >
      {props.children}
    </RUMContext.Provider>
  );
}
