import theme from '../../theme/theme';

export function getSelectStyles(args?: { error?: boolean }) {
  return {
    // @ts-ignore
    container: (provided) => {
      return {
        ...provided,
        width: '100%',
      };
    },
    // @ts-ignore
    control: (provided) => {
      return {
        ...provided,
        minHeight: '40px',
        border: args?.error
          ? `1px solid ${theme.colorsPalette.error.default}`
          : provided.border,
        fontSize: theme.fontSizes.regular,
        color: theme.colorsPalette.gray.light,
      };
    },
    // @ts-ignore
    singleValue: (provided) => {
      return {
        ...provided,
        fontSize: theme.fontSizes.regular,
        color: theme.colorsPalette.gray.light,
      };
    },
    // @ts-ignore
    clearIndicator: (provided) => {
      return { ...provided, padding: '0 4px' };
    },
    // @ts-ignore
    dropdownIndicator: (provided) => {
      return { ...provided, padding: '0 4px' };
    },
    // @ts-ignore
    valueContainer: (provided) => {
      return { ...provided, minHeight: '32px' };
    },
    // @ts-ignore
    multiValue: (provided) => {
      return { ...provided, backgroundColor: theme.colorsPalette.gray.darker };
    },
    // @ts-ignore
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 3,
      };
    },
    // @ts-ignore
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: theme.fontSizes.regular,
        color:
          state.isFocused || state.isSelected
            ? theme.colorsPalette.gray.darker
            : theme.colorsPalette.white.dark,
        '&:hover': { color: theme.colorsPalette.gray.darker },
        '&:active': { color: theme.colorsPalette.gray.darker },
        '&:focus': { color: theme.colorsPalette.gray.darker },
      };
    },
  };
}
