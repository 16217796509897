import { colorsPalette } from './theme.colors';
import { fontSizes, fontWeights } from './theme.fonts';

const theme: TTheme = {
  colorsPalette,
  fontSizes,
  fontWeights,
};

export default theme;
