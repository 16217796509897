import { createContext } from 'react';

import { TForm, TFormProps } from './Form.types';
import {
  FormCheckbox,
  FormContentEditor,
  FormField,
  FormFooter,
  FormGroup,
  FormLabel,
  FormLanguageSelect,
  FormSelect,
} from './Form.components';
import { BaseForm } from './Form.styles';

export const FormContext = createContext({});

export default function Form<TValues = any>({
  children,
  form,
  style,
  className,
}: TFormProps<TValues>) {
  return (
    <FormContext.Provider value={form}>
      <BaseForm
        onSubmit={form.handleSubmit}
        style={style}
        className={className}
      >
        {children}
      </BaseForm>
    </FormContext.Provider>
  ) as TForm;
}

Form.Field = FormField;
Form.Label = FormLabel;
Form.Select = FormSelect;
Form.LanguageSelect = FormLanguageSelect;
Form.Checkbox = FormCheckbox;
Form.ContentEditor = FormContentEditor;
Form.Group = FormGroup;
Form.Footer = FormFooter;
