import BaseSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { getSelectStyles } from './Select.helpers';
import { selectTheme } from './Select.styles';
import { TSelectProps } from './Select.types';

export default function Select<T = unknown, IsMulti extends boolean = false>({
  variant = 'base',
  error,
  ...baseProps
}: TSelectProps<T, IsMulti>) {
  const props = {
    theme: selectTheme,
    styles: getSelectStyles({ error }),
    classNamePrefix: 'react-select',
    ...baseProps,
  };

  switch (variant) {
    case 'base':
      return <BaseSelect {...props} />;

    case 'creatable':
      return <CreatableSelect {...props} />;

    default:
      return null;
  }
}
