import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeContextProvider } from './context/ThemeContext/ThemeContext';
import { QuoteFilesContextProvider } from './context/QuoteFilesContext/QuoteFilesContext';

import Routes from './routes/Routes';
import { RUMContextProvider } from './context/RUMContext/RUMContext';
import { AlertsContextProvider } from './context/AlertsContext/AlertsContext';

export default function App() {
  return (
    <Router>
      <ThemeContextProvider>
        <AlertsContextProvider>
          <RUMContextProvider>
            <QuoteFilesContextProvider>
              <Routes />
            </QuoteFilesContextProvider>
          </RUMContextProvider>
        </AlertsContextProvider>
      </ThemeContextProvider>
    </Router>
  );
}
