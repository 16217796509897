import theme from '../../theme/theme';

import { TCheckboxProps } from './Checkbox.types';
import {
  CheckboxContainer,
  HiddenCheckbox,
  BaseCheckbox,
  CheckboxIcon,
  CheckboxLabel,
} from './Checkbox.styles';

export default function Checkbox({
  checked,
  label,
  color = theme.colorsPalette.primary.default,
  className,
  style,
  ...props
}: TCheckboxProps) {
  return (
    <CheckboxContainer className={className} style={style?.root}>
      <HiddenCheckbox checked={checked} {...props} />

      <BaseCheckbox
        checked={checked}
        disabled={props.disabled}
        color={color}
        style={style?.checkbox}
      >
        <CheckboxIcon viewBox='0 0 24 24' style={style?.icon}>
          <polyline points='20 6 9 17 4 12' />
        </CheckboxIcon>
      </BaseCheckbox>

      {label &&
        (typeof label === 'string' ? (
          <CheckboxLabel style={style?.label}>{label}</CheckboxLabel>
        ) : (
          label
        ))}
    </CheckboxContainer>
  );
}
