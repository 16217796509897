import {
  SERVICES,
  SOURCE_LANGUAGES,
} from '../../../constants/translation.constants';
import { TQuoteRequestFormValues } from './QuoteRequestSection.types';

export const QUOTE_REQUEST_FORM_INITIAL_VALUES: TQuoteRequestFormValues = {
  source_language: SOURCE_LANGUAGES.find(
    (language) => language.code === 'en'
  ) as TLanguage<TSourceLanguageCode>,
  target_languages: [],
  services: SERVICES.filter((service) => service.code === 'text_localization'),
  userEmail: '',
  userName: '',
  merchantName: '',
  instructions: '',
  tcAgreed: false,
};
