import { createContext, useCallback, useRef } from 'react';
import {
  Editor,
  EditorState,
  convertToRaw,
  RichUtils,
  DefaultDraftBlockRenderMap,
  DraftHandleValue,
  ContentState,
  ContentBlock,
  AtomicBlockUtils,
} from 'draft-js';
import { draftjsToMd } from 'draftjs-md-converter';

import 'draft-js/dist/Draft.css';

import {
  TContentEditor,
  TContentEditorContextValues,
  TContentEditorProps,
} from './ContentEditor.types';
import { CONTENT_EDITOR_CUSTOM_CONFIGS } from './ContentEditor.constants';
import { useContentEditorState } from './ContentEditor.hooks';
import {
  ContentEditorContainer,
  ContentEditorInnerContainer,
} from './ContentEditor.styles';
import {
  ContentEditorControlButton,
  ContentEditorControls,
  ContentEditorHeader,
  ContentEditorSubmit,
} from './ContentEditor.components';

export const ContentEditorContext = createContext(
  {} as TContentEditorContextValues
);

export default function ContentEditor({
  value,
  onChange,
  onImagesChange,
  placeholder,
  readOnly,
  style,
  className: baseClassName,
  children,
}: TContentEditorProps) {
  const editorRef = useRef<Editor>(null);

  const { editorState, setEditorState } = useContentEditorState({
    value,
    readOnly,
  });

  const handleReset = useCallback(() => {
    const newState = EditorState.push(
      editorState,
      ContentState.createFromText(''),
      'insert-characters'
    );

    setEditorState(newState);
  }, [editorState, setEditorState]);

  function getBlockStyle(block: ContentBlock) {
    const blockType = block.getType();

    switch (blockType) {
      case 'blockquote':
        return 'RichEditor-blockquote';

      case 'atomic':
        return 'RichEditor-image';

      default:
        return '';
    }
  }

  function getMDFromState() {
    const contentState = editorState.getCurrentContent();
    const rawObject = convertToRaw(contentState);
    const md = draftjsToMd(
      rawObject,
      CONTENT_EDITOR_CUSTOM_CONFIGS.draftJsToMd
    );

    return md;
  }

  function focusEditor() {
    editorRef?.current && editorRef.current.focus();
  }

  function toggleBlockType(blockType: any) {
    setEditorState((editorState) =>
      RichUtils.toggleBlockType(editorState, blockType)
    );
  }

  function toggleInlineStyle(inlineStyle: any) {
    setEditorState((editorState) =>
      RichUtils.toggleInlineStyle(editorState, inlineStyle)
    );
  }

  function toggleLinkStyle() {
    const link = window.prompt('Pega aquí tu link');
    const selection = editorState.getSelection();
    const editorContent = editorState.getCurrentContent();
    const contentWithEntity = editorContent.createEntity('LINK', 'MUTABLE', {
      url: link,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      'apply-entity'
    );

    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));

    const newMDString = getMDFromState();

    onChange && onChange(newMDString);

    setTimeout(() => focusEditor(), 0);

    return 'handled';
  }

  function handleKeyCommand(command: string): DraftHandleValue {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);

      return 'handled';
    }

    return 'not-handled';
  }

  function handlePastedFiles(files: Array<Blob>): DraftHandleValue {
    const reader = new FileReader();

    reader.onloadend = function () {
      const url = reader.result;

      const editorContent = editorState.getCurrentContent();

      const contentWithEntity = editorContent.createEntity(
        'IMAGE',
        'IMMUTABLE',
        {
          src: url,
        }
      );

      const entityKey = contentWithEntity.getLastCreatedEntityKey();

      const newState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
      );

      setEditorState(newState);

      onImagesChange && onImagesChange({ file: files[0], url });
    };

    if (files[0]) reader.readAsDataURL(files[0]);

    return 'handled';
  }

  let className = baseClassName?.editor || '';

  const contentState = editorState.getCurrentContent();
  const md = getMDFromState();

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += 'RichEditor-hidePlaceholder';
    }
  }

  return (
    <ContentEditorContext.Provider
      value={{
        editorState,
        onToggleInlineStyle: toggleInlineStyle,
        onToggleBlockType: toggleBlockType,
        onToggleLinkStyle: toggleLinkStyle,
        handleReset,
      }}
    >
      <ContentEditorContainer
        className={baseClassName?.root}
        style={style?.root}
      >
        <ContentEditorInnerContainer
          className={className}
          style={style?.editor}
          onClick={focusEditor}
        >
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={(editorState) => {
              setEditorState(editorState);

              onChange && onChange(md, handleReset);
            }}
            blockStyleFn={getBlockStyle}
            handleKeyCommand={handleKeyCommand}
            handlePastedFiles={handlePastedFiles}
            blockRenderMap={DefaultDraftBlockRenderMap}
            customStyleMap={CONTENT_EDITOR_CUSTOM_CONFIGS.styleMap}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        </ContentEditorInnerContainer>

        {children}
      </ContentEditorContainer>
    </ContentEditorContext.Provider>
  ) as unknown as TContentEditor;
}

ContentEditor.ControlButton = ContentEditorControlButton;
ContentEditor.Controls = ContentEditorControls;
ContentEditor.Submit = ContentEditorSubmit;
ContentEditor.Header = ContentEditorHeader;
