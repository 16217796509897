import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export function getFilesMaxExpDate(files: TLocalizationFile[]) {
  const formattedFiles = files.map((file) => dayjs(file.expires_at));

  const maxExpDate = dayjs.max(formattedFiles);

  return maxExpDate;
}

export function getFilesExpTimeText(
  maxExpDate?: dayjs.Dayjs,
  unit: dayjs.QUnitType | dayjs.OpUnitType = 'hours'
) {
  if (!maxExpDate) return;

  const expTime = maxExpDate.diff(dayjs(), unit);

  const text = `${expTime} ${unit}`;

  return text;
}
