export const LANGUAGE_NAMES: Record<TLanguageCode, string> = {
  ar: 'Arabic',
  bg: 'Bulgarian',
  be: 'Belarusian',
  'zh': 'Chinese',
  // 'zh-tr': 'Chinese (Traditional)',
  // 'zh-si': 'Chinese (PRC)',
  da: 'Danish',
  de: 'German',
  en: 'English',
  'en-gb': 'English (UK)',
  'en-us': 'English (US)',
  fi: 'Finnish',
  fr: 'French',
  el: 'Greek',
  hi: 'Hindi',
  it: 'Italian',
  ja: 'Japanese',
  hr: 'Croatian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  nl: 'Dutch',
  no: 'Norwegian',
  'pt': 'Portuguese',
  'pt-br': 'Portuguese (Brazil)',
  'pt-pt': 'Portuguese (Portugal)',
  ru: 'Russian',
  sv: 'Swedish',
  sr: 'Serbian (Latin)',
  sk: 'Slovak',
  sl: 'Slovenian',
  cs: 'Czech',
  tr: 'Turkish',
  hu: 'Hungarian',
  ko: 'Korean',
  pl: 'Polish',
  'es': 'Spanish',
  'es-es': 'Spanish (Spain)',
  'es-la': 'Spanish (LATAM)',
  'es-mx': 'Spanish (Mexican)',
  uk: 'Ukrainian',
  mk: 'Macedonian',
  mn: 'Mongolian',
  my: 'Burmese',
  et: 'Estonian',
  sq: 'Albanian',
  bn: 'Bengali',
  bs: 'Bosnian',
  ca: 'Catalan',
  fa: 'Persian (Farsi)',
  gu: 'Gujarati',
  he: 'Hebrew',
  id: 'Indonesian',
  ka: 'Kannada',
  ks: 'Kashmiri',
  // kh: 'Khmer',
  km: 'Central Khmer',
  ku: 'Kurdish',
  lo: 'Lao',
  ms: 'Malay',
  mr: 'Marathi',
  ml: 'Malayalam',
  ne: 'Nepali',
  or: 'Oriya',
  pa: 'Pashto',
  ro: 'Romanian',
  si: 'Sinhala',
  tl: 'Tagalog',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  ur: 'Urdu',
  vi: 'Vietnamese',
  'fr-be': 'French (Belgium)',
  'sr-cy': 'Serbian (Cyryllic)',
  'fr-ca': 'French (Canada)',
  ps: 'Dari',
  // fl: 'Filipino',
};

export const SOURCE_LANGUAGES_CODES: TSourceLanguageCode[] = [
  'en',
  'en-us',
  'en-gb',
  'fr',
  'fr-ca',
  'fr-be',
  'it',
  'de',
  'es',
  'es-es',
  'es-la',
  'es-mx',
  'ru',
  'ja',
  'zh',
  // 'zh-tr',
  // 'zh-si',
  'ko',
];

export const TARGET_LANGUAGES_CODES: TTargetLanguageCode[] = [
  'ar',
  'bg',
  'be',
  'zh',
  // 'zh-tr',
  // 'zh-si',
  'da',
  'de',
  'en',
  'en-gb',
  'en-us',
  'fi',
  'fr',
  'el',
  'hi',
  'it',
  'ja',
  'hr',
  'lv',
  'lt',
  'nl',
  'no',
  'pt',
  'pt-br',
  'pt-pt',
  'ru',
  'sv',
  'sr',
  'sk',
  'sl',
  'cs',
  'tr',
  'hu',
  'ko',
  'pl',
  'es',
  'es-es',
  'es-la',
  'es-mx',
  'uk',
  'mk',
  'mn',
  'km',
  'et',
  'sq',
  'bn',
  'bs',
  'ca',
  'fa',
  'gu',
  'he',
  'id',
  'ka',
  'ks',
  // 'kh',
  'ku',
  'lo',
  'ms',
  'my',
  'mr',
  'ml',
  'ne',
  'or',
  'pa',
  'ro',
  'si',
  'tl',
  'ta',
  'te',
  'th',
  'ur',
  'vi',
  'fr-be',
  'sr-cy',
  'fr-ca',
  'ps',
  // 'fl',
];

export const LANGUAGES: TLanguage[] = Object.entries(LANGUAGE_NAMES).map(
  ([code, name]) =>
    ({
      code,
      name,
    } as TLanguage)
);

export const SOURCE_LANGUAGES: TLanguage[] = LANGUAGES.filter((language) =>
  SOURCE_LANGUAGES_CODES.includes(language.code as any)
);

export const TARGET_LANGUAGES: TLanguage[] = LANGUAGES.filter((language) =>
  TARGET_LANGUAGES_CODES.includes(language.code as any)
);

export const SERVICE_NAMES: Record<TServiceCode, string> = {
  text_localization: 'Text localization',
  quality_assurance: 'Quality Assurance',
  audio_production: 'Audio Production',
  voice_over_recording: 'Voice-Over RecordinG',
};

export const SERVICES: TService[] = Object.entries(SERVICE_NAMES).map(
  ([code, name]) =>
    ({
      code,
      name,
    } as TService)
);
