import Select from '../Select/Select';
import {
  SOURCE_LANGUAGES,
  TARGET_LANGUAGES,
} from '../../../constants/translation.constants';

import { TLanguageSelectProps } from './LanguageSelect.types';

export default function LanguageSelect<IsMulti extends boolean = false>({
  languageVariant,
  ...props
}: TLanguageSelectProps<IsMulti>) {
  let options = [] as TLanguage[];

  switch (languageVariant) {
    case 'source':
      options = SOURCE_LANGUAGES;
      break;

    case 'target':
      options = TARGET_LANGUAGES;
      break;

    default:
      break;
  }

  return (
    <Select<TLanguage, IsMulti>
      options={options}
      getOptionValue={(l) => l.code}
      getOptionLabel={(l) => l.name}
      {...props}
    />
  );
}
