import styled from 'styled-components';

import { TBaseContainerProps } from './Container.types';

export const BaseContainer = styled.div<TBaseContainerProps>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ row }) => (row ? 'flex-start' : 'center')};
  align-items: ${({ row }) => (row ? 'center' : 'flex-start')};
  max-width: ${({ fluid }) => (fluid ? '760px' : 'unset')};
  margin: ${({ fluid }) => (fluid ? 'auto' : 'unset')};
  padding: ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return `12px`;
      case 'small':
        return `16px`;
      case 'regular':
        return `24px`;
      case 'xlarge':
        return `40px`;
      default:
        return `0px`;
    }
  }};
  background-color: ${({ theme, bgColor }) => {
    switch (bgColor) {
      case 'light':
        return theme.colorsPalette.black.lighter;
      case 'default':
        return theme.colorsPalette.black.light;
      case 'dark':
        return theme.colorsPalette.black.default;
      default:
        return `transparent`;
    }
  }};
  border-radius: ${({ borderRadius }) => {
    switch (borderRadius) {
      case 'rect':
        return `0px`;
      case 'rounded':
        return `4px`;
      default:
        return `0px`;
    }
  }};
`;
