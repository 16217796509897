import styled from 'styled-components';
import { TTypographyProps } from './Typography.types';

export const BaseTypography = styled.p<Pick<TTypographyProps, 'variant'>>`
  ${({ theme, variant }) => {
    switch (variant) {
      case 'title1':
        return `
          font-size: 48px;
          font-weight: ${theme.fontWeights.bolder};
          color: ${theme.colorsPalette.white.dark};
          margin: 0 0 24px 0;
          max-width: 760px;
          line-height: 48px;
          letter-spacing: -0.015em;
          font-family: 'Open Sans', sans-serif;
        `;
      case 'title2':
        return `
          font-size: 36px;
          font-weight: ${theme.fontWeights.bold};
          color: ${theme.colorsPalette.white.dark};
          margin: 0 0 24px 0;
          max-width: 760px;
          line-height: 48px;
          letter-spacing: -.003em;
          font-family: 'Open Sans', sans-serif;
        `;
      case 'title3':
        return `
          font-size: 24px;
          font-weight: ${theme.fontWeights.bold};
          color: ${theme.colorsPalette.white.dark};
          margin: 0 0 16px 0;
          max-width: 760px;
          line-height: 32px;
          letter-spacing: 0em;
          font-family: 'Open Sans', sans-serif;
        `;
      case 'title4':
        return `
          font-size: 18px;
          font-weight: ${theme.fontWeights.regular};
          color: ${theme.colorsPalette.white.dark};
          margin: 0 0 16px 0;
          max-width: 760px;
          line-height: 24px;
          letter-spacing: 0em;
          font-family: 'Open Sans', sans-serif;
        `;
      case 'subtitle1':
        return `
          color: ${theme.colorsPalette.gray.light};
          font-weight: ${theme.fontWeights.bold};
          font-size: 18px;
          letter-spacing: 1px;
          line-height: 24px;
          margin: 0 0 12px 0;
          font-family: 'Open Sans', sans-serif;
        `;
      case 'paragraph1':
        return `
          color: ${theme.colorsPalette.gray.default};
          font-size: 18px;
          font-weight: ${theme.fontWeights.regular};
          margin: 0 0 16px 0;
          line-height: 22px; 
          font-family: 'Quicksand', sans-serif;         
        `;
      case 'paragraph2':
        return `
          color: ${theme.colorsPalette.gray.default};
          font-size: 16px;
          margin: 0;
          line-height: 20px;  
          font-family: 'Quicksand', sans-serif;        
        `;
      case 'paragraph3':
        return `
          color: ${theme.colorsPalette.gray.default};
          font-size: 14px;
          margin: 0;
          line-height: 18px;   
          font-family: 'Quicksand', sans-serif;       
        `;

      case 'paragraph4':
        return `
          color: ${theme.colorsPalette.gray.default};
          font-size: 12px;
          margin: 0;
          line-height: 100%;   
          font-family: 'Quicksand', sans-serif;       
        `;

      case 'link':
        return `
          text-decoration:none;
          color: ${theme.colorsPalette.primary.default};
          font-size: 14px;
          margin: 0;
          line-height: 18px;   
          font-family: 'Quicksand', sans-serif;       
      `;

      default:
        return ``;
    }
  }}
  @media (max-width: 600px) {
    /* width: 100%; */
    ${({ variant }) => {
      switch (variant) {
        case 'title1':
          return `
            font-size: 40px;
          `;
        case 'title2':
        case 'title3':
          return `
            font-size: 24px;
            margin: 0 0 16px 0;
          `;
        case 'paragraph1':
          return `
              font-size: 16px;
            `;
        case 'paragraph2':
          return `
              font-size: 14px;
            `;
        default:
          return ``;
      }
    }}
  }
`;
