import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';

import {
  TRestAPIRequestError,
  TRestMutationMethod,
  TRestRequestOptions,
  TUseRestMutationHook,
} from './types.rest';
import RestAPIClient from './RestAPIClient';

export function useRestMutation<TData = unknown, TVariables = unknown>(
  { url, baseURL }: { url: string; baseURL: string },
  method: TRestMutationMethod,
  options?: TRestRequestOptions<TData, TVariables>
): TUseRestMutationHook<TData, TVariables> {
  const [data, setData] = useState<TData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TRestAPIRequestError | undefined>(
    undefined
  );

  const { mock, ...axiosOptions } = options || {};

  const mutation = useCallback(
    (variables?: TVariables) => {
      setLoading(true);

      const apiClient = RestAPIClient.getInstance({ mock, url });

      apiClient({
        baseURL,
        url,
        method,
        data: variables,
        ...axiosOptions,
      })
        .then((response: AxiosResponse<TData>) => {
          setData(response.data);

          options?.onCompleted && options.onCompleted(response.data, variables);

          setLoading(false);
        })
        .catch((error: TRestAPIRequestError) => {
          setError(error);

          options?.onError && options.onError(error);

          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, url]
  );

  return [mutation, { data, loading, error }];
}
