import { useRestMutation } from '../mutations.rest';
import { QUOTE_REQUESTER_API_V1_BASE_URL } from '../../../constants/api.constants';
import { useAlerts } from '../../../app/context/AlertsContext/AlertsContext.hooks';

import { TRestRequestOptions } from '../types.rest';

type TRequestQuoteMutationVariables = WithId & {
  source_language: TSourceLanguageCode;
  target_languages: TTargetLanguageCode[];
  services: string[];
  user_email: string;
  user_full_name: string;
  company_name: string;
  instructions: string;
  tc_agreed: boolean;
};

export function useRequestQuoteMutation(
  options?: TRestRequestOptions<Distinct<TQuote>>
) {
  const { showErrorSnackbar } = useAlerts();

  const mutation = useRestMutation<
    Distinct<TQuote>,
    TRequestQuoteMutationVariables
  >(
    { baseURL: QUOTE_REQUESTER_API_V1_BASE_URL, url: '/quote-requests' },
    'post',
    {
      onCompleted(response) {
        options?.onCompleted && options.onCompleted(response);
      },
      onError(error) {
        showErrorSnackbar({
          message: 'Error sending your request. Please try again later.',
        });

        options?.onError && options.onError(error);
      },
    }
  );

  return mutation;
}
