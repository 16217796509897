import { CSSProperties } from 'react';

import theme from '../../theme/theme';

import { TFileProps } from './File.types';

export function getFileRootStyle({
  style: baseStyle,
  highlight,
}: Pick<TFileProps, 'style' | 'highlight'>) {
  let style: CSSProperties = {
    ...baseStyle?.root,
    justifyContent: 'space-between',
  };

  if (highlight) {
    style = {
      border: `1px solid ${theme.colorsPalette.cyan.default}`,
      ...style,
    };
  }

  return style;
}

export function getFileNameStyle({
  style: baseStyle,
  highlight,
}: Pick<TFileProps, 'style' | 'highlight'>) {
  let style: CSSProperties = {
    ...baseStyle?.name,
  };

  if (highlight) {
    style = {
      display: 'flex',
      alignItems: 'center',
      height: 24,
      paddingLeft: 8,
      borderLeft: `3px solid ${theme.colorsPalette.cyan.default}`,
      ...style,
    };
  }

  return style;
}
