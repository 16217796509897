import { useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';

import { useRUM } from '../../context/RUMContext/RUMContext.hooks';
import { useQuoteFiles } from '../../context/QuoteFilesContext/QuoteFilesContext.hooks';
import { useRequestQuoteMutation } from '../../../api/rest/resources/quotes.mutations';
import Container from '../../components/Container/Container';

import { QUOTE_REQUEST_FORM_INITIAL_VALUES } from './QuoteRequestSection.constants';
import {
  TQuoteRequestFormValues,
  TQuoteRequestState,
} from './QuoteRequestSection.types';
import { quoteRequestFormValidationSchema } from './QuoteRequestSection.helpers';
import {
  QuoteRequestFilesList,
  QuoteRequestForm,
  QuoteRequestHeader,
  QuoteRequestOnCompletedMessage,
} from './QuoteRequestSection.components';

export default function QuoteRequestSection() {
  const [requestState, setRequestState] =
    useState<TQuoteRequestState>('inProgress');

  const [searchParams] = useSearchParams();
  const quoteRequestId = searchParams.get('quote_request_id');

  const { ga } = useRUM();

  const {
    data: quoteFilesData,
    loading: loadingQuoteFilesData,
    error: errorOnQuoteFilesData,
  } = useQuoteFiles();

  const [requestQuoteMutation, { loading: loadingRequestQuoteMutation }] =
    useRequestQuoteMutation({
      onCompleted() {
        setRequestState('completed');

        ga.handleTrackEvent({
          category: 'Quotes',
          action: 'Submit quote',
          label: `User name: ${form.values.userName}`,
        });
      },
    });

  const form = useFormik<TQuoteRequestFormValues>({
    initialValues: QUOTE_REQUEST_FORM_INITIAL_VALUES,
    validationSchema: quoteRequestFormValidationSchema,
    onSubmit(values) {
      requestQuoteMutation({
        id: quoteRequestId || '',
        source_language: values.source_language.code,
        target_languages: values.target_languages.map(
          (language) => language.code as TTargetLanguageCode
        ),
        services: values.services.map((service) => service.code),
        user_email: values.userEmail,
        user_full_name: values.userName,
        company_name: values.merchantName,
        instructions: values.instructions || '',
        tc_agreed: values.tcAgreed,
      });
    },
  });

  return (
    <Container as='section' fluid size='regular'>
      {requestState === 'inProgress' ? (
        <>
          <QuoteRequestHeader />

          <QuoteRequestFilesList
            data={quoteFilesData}
            loading={loadingQuoteFilesData}
            error={errorOnQuoteFilesData}
          />

          {!errorOnQuoteFilesData && !loadingQuoteFilesData && (
            <QuoteRequestForm
              form={form}
              loadingRequestQuoteMutation={loadingRequestQuoteMutation}
            />
          )}
        </>
      ) : requestState === 'completed' ? (
        <QuoteRequestOnCompletedMessage />
      ) : null}
    </Container>
  );
}
