import { BaseTypography } from './Typography.styles';
import { TTypographyProps } from './Typography.types';

export default function Typography({
  children,
  variant,
  ...props
}: TTypographyProps) {
  return (
    <BaseTypography variant={variant} {...props}>
      {children}
    </BaseTypography>
  );
}
