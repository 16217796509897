import theme from '../theme/theme';

export default function CloseIcon({
  height = '24px',
  color = theme.colorsPalette.primary.lighter,
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      style={style}
      className={className}
      viewBox='0 0 16 17'
      fill='none'
      id='CloseIcon'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.54206 8.50139L15.6788 2.36528C16.1084 1.93573 16.1057 1.24754 15.6788 0.819958C15.2512 0.390411 14.563 0.395649 14.1361 0.819958L7.99999 6.95607L1.86388 0.819958C1.43433 0.390411 0.746143 0.39303 0.321179 0.819958C-0.108368 1.25016 -0.105749 1.9377 0.321179 2.36528L6.45795 8.50139L0.321179 14.6349C-0.108368 15.0644 -0.105749 15.7526 0.321179 16.1796C0.748763 16.6098 1.43695 16.6072 1.86388 16.1796L7.99999 10.0435L14.1361 16.1796C14.5657 16.6098 15.2539 16.6072 15.6788 16.1796C16.1084 15.75 16.1057 15.0618 15.6788 14.6349L9.54206 8.50139Z'
        fill={color}
      />
    </svg>
  );
}
