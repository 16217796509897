const fontSizes: TFontSizes = {
  huge: '64px',
  xxxlarge: '32px',
  xxlarge: '28px',
  xlarge: '24px',
  regularLarge: '20px',
  regular: '14px',
  regularSmall: '13px',
  small: '12px',
  xsmall: '11px',
  xxsmall: '10px',
  xxxsmall: '9px',
  tiny: '8px',
};

const fontWeights: TFontsWeights = {
  bolder: 700,
  bold: 600,
  semibold: 500,
  regular: 400,
  light: 200,
  lighter: 200,
};

export { fontSizes, fontWeights };
