export async function handleMediaDownload(data: any, filename: string) {
  const blob = new Blob([data]);

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.href = url;

  a.setAttribute('download', filename);

  document.body.appendChild(a);

  a.click();
  a.remove();
}
