import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from '../../theme/theme';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${({ theme }) => theme.colorsPalette.black.default};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export function ThemeContextProvider({ children }: WithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        {children}
      </>
    </ThemeProvider>
  );
}
