export default function AlocaiLogoIcon({
  height = '26px',
  style,
  className,
}: TIconProps) {
  return (
    <svg
      height={height}
      style={style}
      className={className}
      viewBox='0 0 157 26'
      fill='none'
      id='AlocaiLogoIcon'
    >
      <path
        d='M25.9751 24.9323L15.6316 3.8928C15.4371 3.56853 15.0872 3.35871 14.6983 3.33964C14.3289 3.35871 13.9789 3.54946 13.8039 3.8928L3.44092 24.9323C3.05206 25.8288 2.00215 26.2485 1.08834 25.8479C0.174526 25.4664 -0.253216 24.4364 0.155083 23.5398C0.174526 23.4826 0.213411 23.4254 0.252297 23.3682L10.6348 2.53849C11.7624 0.306741 14.5233 -0.589769 16.7787 0.516569C17.6925 0.955289 18.4313 1.68013 18.8591 2.57664L29.2026 23.3872C29.7276 24.4363 29.0082 25.9242 27.6083 25.9242C26.9084 25.9242 26.2862 25.5427 25.9751 24.9323ZM12.3846 16.7111C12.3846 15.414 13.279 14.5747 14.7177 14.5747C16.1565 14.5747 17.0509 15.414 17.0509 16.7111C17.0509 18.0081 16.1565 18.8474 14.7177 18.8474C13.279 18.8474 12.3846 18.0272 12.3846 16.7111Z'
        fill='#F2F2F7'
      />
      <path
        d='M38.1263 25.5997C35.7543 25.5997 33.81 23.7113 33.81 21.4414V1.81347C33.7711 0.840661 34.5488 0.0204454 35.5404 0.00137057C36.532 -0.036779 37.368 0.726211 37.3875 1.69903C37.3875 1.73717 37.3875 1.77533 37.3875 1.81347V21.6131C37.4069 21.9946 37.7374 22.2807 38.1263 22.2616H54.4971C55.4498 22.2235 56.2276 22.9483 56.2664 23.883C56.2664 23.9021 56.2664 23.9212 56.2664 23.9212C56.2664 24.894 55.4887 25.5807 54.4971 25.5807H38.1263V25.5997Z'
        fill='#F2F2F7'
      />
      <path
        d='M57.0057 12.9725C57.0057 4.63682 63.9079 0.0397949 71.5295 0.0397949C79.6177 0.0397949 86.0144 4.99924 86.0144 12.9725C86.0144 20.6215 80.2205 25.9052 71.5295 25.9052C63.4219 25.9243 57.0057 21.1365 57.0057 12.9725ZM60.5832 12.9725C60.5832 18.6568 64.8023 22.5862 71.5101 22.5862C78.2179 22.5862 82.3981 18.6759 82.3981 12.9725C82.3981 7.26915 78.2179 3.35881 71.5101 3.35881C64.8023 3.35881 60.5832 7.28822 60.5832 12.9725Z'
        fill='#F2F2F7'
      />
      <path
        d='M90.4282 12.9725C90.4282 4.52237 97.1554 0.0397949 105.458 0.0397949C109.366 0.0397949 112.768 1.05076 115.354 2.90102C116.132 3.51141 116.248 4.63682 115.626 5.39981C115.043 6.12465 113.993 6.27726 113.215 5.76224C111.251 4.31255 108.588 3.37789 105.458 3.37789C99.1386 3.37789 94.0057 6.56338 94.0057 12.9916C94.0057 18.7903 98.2831 22.5862 105.458 22.5862C108.607 22.5862 111.251 21.6516 113.215 20.2019C114.012 19.6296 115.14 19.8013 115.723 20.5643C116.326 21.3273 116.17 22.4336 115.373 23.0059L115.354 23.0249C112.768 24.9515 109.366 25.9243 105.458 25.9243C96.3388 25.9243 90.4282 20.736 90.4282 12.9725Z'
        fill='#F2F2F7'
      />
      <path
        d='M145.587 24.9319L135.243 3.87336C135.049 3.54909 134.699 3.33927 134.31 3.32019C133.94 3.33927 133.59 3.53001 133.415 3.87336L123.033 24.9319C122.605 25.8094 121.536 26.1718 120.642 25.7521C119.747 25.3325 119.378 24.2834 119.805 23.4059C119.805 23.3869 119.825 23.3869 119.825 23.3678L130.207 2.53812C131.335 0.306375 134.096 -0.590135 136.351 0.516202C137.265 0.954922 138.004 1.67976 138.432 2.57628L148.775 23.3869C149.3 24.436 148.581 25.9238 147.181 25.9238C146.52 25.9238 145.898 25.5423 145.587 24.9319ZM131.996 16.7107C131.996 15.4136 132.89 14.5743 134.329 14.5743C135.768 14.5743 136.662 15.4136 136.662 16.7107C136.662 18.0078 135.768 18.8471 134.329 18.8471C132.89 18.8471 131.996 18.0268 131.996 16.7107Z'
        fill='#41DDD3'
      />
      <path
        d='M153.422 24.1692V1.8135C153.422 0.84069 154.239 0.0395508 155.23 0.0395508C156.222 0.0395508 157 0.821614 157 1.79443V1.8135V24.1692C157.019 25.1229 156.241 25.905 155.269 25.905C155.25 25.905 155.23 25.905 155.23 25.905C154.239 25.924 153.442 25.142 153.422 24.1882C153.422 24.1882 153.422 24.1882 153.422 24.1692Z'
        fill='#41DDD3'
      />
    </svg>
  );
}
