const baseColors: Record<TBaseColor, TColorVariants> = {
  black: {
    default: '#1E202A',
    light: '#282A36',
    lighter: '#2C2F3D',
    dark: '#091328',
    darker: '#0D0E12',
  },
  blue: {
    default: 'hsl(209, 100%, 50%)', // #0084ff
    light: 'hsl(209, 100%, 70%)', // #66b5ff
    lighter: 'hsl(208, 100%, 97%)', // #f0f8ff
    dark: 'hsl(209, 100%, 35%)', // #005cb3
    darker: 'hsl(209, 75%, 25%)',
    superDark: 'hsl(223, 46%, 24%)',
  },
  cyan: {
    default: '#1DBBFA',
    light: '#66F0FF',
    lighter: '#CCFCFF',
    dark: '#0072B7',
    darker: '#003A7A',
  },
  gray: {
    default: '#D3D3E4',
    light: '#F2F2F7',
    dark: '#B3B3D0',
    darker: '#515871',
  },
  green: {
    default: '#57D196',
    light: '#A7E7C8',
    lighter: '#CFF2E1',
    dark: '#299863',
    darker: '#1F7049',
  },
  purple: {
    default: '#D960A2',
    light: '#ECB1D1',
    lighter: '#F6DAE9',
    dark: '#A9286E',
    darker: '#801E53',
  },
  red: {
    default: '#E95671',
    light: '#F5B2BE',
    lighter: '#FBDFE4',
    dark: '#C21A38',
    darker: '#680E1E',
  },
  turquoise: {
    default: '#41DDD3',
    light: '#97ECE7',
    lighter: '#C2F4F1',
    dark: '#1C9C94',
    darker: '#14706B',
  },
  yellow: {
    default: '#FEC64C',
    light: '#F3D587',
    lighter: '#FCECC9',
    dark: '#E29B00',
    darker: '#986D10',
  },
  white: {
    default: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#F2F2F7',
  },
};

export const colorsPalette: TColorPalette = {
  primary: {
    default: baseColors.turquoise.default,
    light: baseColors.turquoise.light,
    lighter: baseColors.turquoise.lighter,
    dark: baseColors.turquoise.dark,
    darker: baseColors.turquoise.darker,
  },
  secondary: {
    default: baseColors.gray.default,
    light: baseColors.gray.light,
    dark: baseColors.gray.dark,
    darker: baseColors.gray.darker,
  },
  success: {
    default: baseColors.green.default,
    light: baseColors.green.light,
    lighter: baseColors.green.lighter,
    dark: baseColors.green.dark,
    darker: baseColors.green.darker,
  },
  info: {
    default: baseColors.purple.default,
    light: baseColors.purple.light,
    lighter: baseColors.purple.lighter,
    dark: baseColors.purple.dark,
    darker: baseColors.purple.darker,
  },
  warning: {
    default: baseColors.yellow.default,
    light: baseColors.yellow.light,
    lighter: baseColors.yellow.lighter,
    dark: baseColors.yellow.dark,
    darker: baseColors.yellow.darker,
  },
  error: {
    default: baseColors.red.default,
    light: baseColors.red.light,
    lighter: baseColors.red.lighter,
    dark: baseColors.red.dark,
    darker: baseColors.red.darker,
  },
  ...baseColors,
};
