import { Theme } from 'react-select';

import theme from '../../theme/theme';

export const selectTheme = (selectTheme: Theme): Theme => ({
  ...selectTheme,
  borderRadius: 4,
  colors: {
    ...selectTheme.colors,
    primary75: theme.colorsPalette.primary.default,
    primary50: theme.colorsPalette.primary.default,
    primary25:
      theme.colorsPalette.primary.lighter || selectTheme.colors.primary25, // focussed/hovered option background: ;
    primary: theme.colorsPalette.primary.light, // select component focus outline
    neutral0: theme.colorsPalette.black.lighter || selectTheme.colors.neutral0, // general background: ;
    neutral5: theme.colorsPalette.black.default, // disabled=true active option background color
    neutral10: theme.colorsPalette.black.default, // disabled=true outlines
    neutral20: theme.colorsPalette.gray.darker || selectTheme.colors.neutral20, // select component outline and controls - no focus
    neutral30: theme.colorsPalette.gray.darker || selectTheme.colors.neutral30,
    neutral40: theme.colorsPalette.white.default, // disabled=true active option text color: ;
    neutral50: theme.colorsPalette.gray.darker || selectTheme.colors.neutral50,
    neutral60: theme.colorsPalette.gray.darker || selectTheme.colors.neutral60,
    neutral70: theme.colorsPalette.gray.darker || selectTheme.colors.neutral70,
    neutral80: theme.colorsPalette.white.default, // active option (value=option) text color
    neutral90: theme.colorsPalette.white.default,
  },
});
