import * as yup from 'yup';

export const quoteRequestFormValidationSchema = yup.object({
  source_language: yup.object().shape({ name: yup.string().required() }),
  target_languages: yup.array().min(1),
  services: yup.array().min(1),
  userName: yup.string().required('Full name required'),
  merchantName: yup.string().required('Company name required'),
  userEmail: yup
    .string()
    .email('Invalid email address')
    .required('Email required'),
});
